.balloons-wrapper {
  max-width: 90%;

  @include large() {
    max-width: 60ch;
  }

  &--questions {
    display: flex;
    flex-direction: column;
    margin-left: auto;
    margin-top: 52px;
    margin-bottom: 40px;
    @include large() {
      padding-bottom: 150px;
    }
  }
  
  &--questions-history {
    @extend .balloons-wrapper--questions;
    @include large() {
      padding-bottom: 0;
      margin-bottom: 0;
    }
  }

  &--start {
    border: 1px dashed $color-dark-blue;
    padding: 14px 10px 0;
    margin: 30px 8px 0 20px;
    margin-left: 15%;
    margin-bottom: 40px;

    @include large() {
      padding: 20px 10px 0;
      margin: 30px 30px 0 30px;
      margin-left: auto;
    }
  }

  ol {
    counter-reset: li;

    li {
      position: relative;
      counter-increment: li;
      margin-left: 28px;

      &::before {
        content: counter(li);
        position: absolute;
        top: 0;
        left: -30px;
        display: inline-block;
        font-size: 32px;
        line-height: 44px;
        color: $color-dark-blue;
        margin-right: 10px;
      }
    }
  }

  .balloons-active {
    &__list {
      margin-bottom: 100px;

      @include large() {
        margin-bottom: 0;
      }
    }
  }

  .question-disabled {
    opacity: 0.2;
  }
}

.dialog-info {
  display: flex;
  align-items: flex-start;
  font-size: 12px;
  line-height: 1.5em;
  margin-bottom: 12px;

  .icon-info {
    margin-right: 8px;
    margin-left: 5px;
    flex: 0 0 auto;
    margin-top: 3px;
  }
}

.dialog-balloon {
  position: relative;
  width: 100%;
  padding: 20px;
  margin-bottom: 14px;
  color: $color-white;
  font-size: 14px;
  line-height: 22px;
  background-color: $color-dark-blue;
  text-align: start;
  transition: 500ms background-color;
  cursor: auto;

  &::after {
    position: absolute;
    content: "";
    right: 0;
    bottom: -9px;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0 17px 10px 0;
    border-color: transparent $color-dark-blue transparent transparent;
    transition: 500ms border-color;
  }

  &:hover {
    text-decoration: none;
  }

  &--clickable {
    &:hover {
      background-color: $color-dark-blue-hovered;
      cursor: pointer;
      
      &:after {
        border-color: transparent $color-dark-blue-hovered transparent transparent;
      }
    }
  }

  @include large() {
    padding: 30px;
    margin-bottom: 20px;
    font-size: 16px;
    line-height: 27px;
  }

  &--answer {
    width: fit-content;
    width: -moz-fit-content;
    background-color: transparent;
    color: $color-dark-blue;
    border: 1px solid $color-dark-blue;
    border-bottom: none;

    &::after {
      right: unset;
      left: -1px;
      bottom: -5px;
      width: 18px;
      height: 10px;
      border-width: 1px;
      transform: skewY(-28deg);
      border-top: 0;
      border-right: 0;
      border-color: $color-dark-blue;
    }

    &::before {
      position: absolute;
      right: 0;
      bottom: 0;
      content: "";
      width: calc(100% - 17px);
      border-bottom: 1px solid $color-dark-blue;
    }
  }

  &--waiting {
    @extend .dialog-balloon--answer;
    padding: 0px 42px 22px 42px;
    font-family: "Times New Roman", Times, serif;
    font-size: 42px !important;

    span {
      animation: blip 1400ms;
      animation-iteration-count: infinite;

      &:nth-child(2) {
        animation-delay: 200ms;
      }
      &:nth-child(3) {
        animation-delay: 400ms;
      }
    }
  }
}

.question-info {
  background-color: $color-light;
  display: flex;
  padding: 20px 30px;
  margin-top: -14px;
  margin-bottom: 20px;

  @include large() {
    margin-top: -20px;
  }

  .icon-info {
    margin-right: 10px;
    flex: 0 0 auto;
  }

  small {
    font-size: 12px;
    line-height: 16px;
  }
}

.replay-button,
.skip-button {
  position: relative;
  display: flex;
  align-items: center;
  @include button-animation();

  &:hover {
    text-decoration: none;
  }

  .icon {
    z-index: 1;
  }
}

.replay-button {
  justify-content: center;
  padding: 5px;
  margin-left: -5px;
  margin-bottom: 13px;
}

.skip-button {
  margin-bottom: 10px;
  padding-right: 10px;
  margin-left: -6px;

  &::before {
    border-radius: 30px;
  }

  span {
    text-transform: capitalize;
    font-size: 14px;
    z-index: 1;
  }
}

.balloon-info {
  display: flex;
  margin-bottom: 10px;
  padding-left: 28px;
  font-size: 12px;
  align-items: center;
  width: 100%;

  button {
    display: flex;
    align-items: center;
    margin-left: auto;
    cursor: pointer;
    background-color: $color-light;
    border-radius: 100px;

    span {
      padding-right: 10px;
      padding-left: 12px;
      font-weight: 400;
      font-size: 12px;
    }
  }
}

.dialog-result {
  padding: 30px 20px;
  border-top: 1px solid $color-dark-blue;
  margin-top: 45px;
  margin-left: -20px;
  margin-right: -20px;

  @include large() {
    padding: 50px 98px;
    margin-left: -30px;
    margin-right: -30px;
  }

  .result-header {
    font-size: 30px;
    line-height: 27px;
    margin-bottom: 25px;
  }

  .result-text {
    font-size: 14px;
    line-height: 27px;

    @include large() {
      font-size: 18px;
      line-height: 30px;
    }
  }

  .result-buttons {
    display: flex;
    gap: 10px;
    flex-wrap: wrap;
  }

  .result-button {
    display: flex;
    align-items: center;
    padding: 15px 20px 12px;
    text-transform: uppercase;
    font-size: 22px;
    line-height: 26px;
    background-color: $color-dark-blue;
    color: $color-pastel-pink;
    margin-top: 30px;

    &--restart {
      background-color: transparent;
      border: 1px solid $color-dark-blue;
      color: $color-dark-blue;
    }
  }

  svg {
    margin-right: 10px;
  }
}

.dialog-box {
  padding: 20px 20px 0;

  @include large() {
    padding: 30px 30px 0;
  }

  &:last-child {
    min-height: calc(100vh - 60px);

    @include large() {
      min-height: calc(100vh - 75px);
    }
  }
}

.start-question__wrapper {
  @include large() {
    margin-left: 30px;
  }
}

.content-dialog::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 6px;
  background-color: #f5f5f5;
}

.content-dialog::-webkit-scrollbar {
  width: 6px;
}

.content-dialog::-webkit-scrollbar-thumb {
  border-radius: 6px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #555;
}

.content-dialog {
  scrollbar-width: thin;
  border-radius: 6px;
  scrollbar-color: #555 #f5f5f5;
}

@keyframes blip {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.hovered-icon {
  &:hover { fill: #3A3E55 };
  transition: 500ms fill;
}