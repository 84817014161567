body,
#root {
  height: 100%;
}

#root {
  height: 100vh;
}

body,
button,
select,
input {
  font-family: "AvantGarde", Arial, Helvetica, sans-serif;
  color: $color-dark-blue;
}

strong {
  font-weight: $font-weight-bold;
}

#root {
  width: 100vw;
  margin: 0;
  padding: 0;
  overflow: hidden;
}

.fade-appear,
.fade-enter,
.fade-video-appear,
.fade-video-enter {
  opacity: 0.01;
}

.fade-appear.fade-appear-active,
.fade-video-appear.fade-video-active,
.fade-enter-active,
.fade-video-enter-active {
  opacity: 1;
  transition: opacity 800ms ease-in;
}

.fade-enter,
.fade-video-enter {
  opacity: 0;
}
.fade-enter-active,
.fade-video-enter-active {
  opacity: 1;
  transition: opacity 800ms ease-in;
}

.fade-exit,
.fade-video-exit {
  opacity: 1;
}

.fade-exit-active,
.fade-video-exit-active {
  opacity: 0;
  transition: opacity 500ms ease-in;
}

.fade-video-exit {
  height: 0 !important;
}

.fade-vide-active {
  height: 0;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active  {
    -webkit-box-shadow: 0 0 0 60px $color-champagne-pink inset !important;
    background-color: $color-champagne-pink !important;
    background-clip: content-box !important;
}