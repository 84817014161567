.background {
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  position: fixed;
  top: 0;
  left: 0;
  z-index: -100;

  &__gradient {
    position: relative;
    top: -100vmax;
    left: -100vmax;
    width: 300vmax;
    height: 300vmax;
    background: linear-gradient(90deg,#00a5ff,#eec1af 55%);
    animation: slow-rotating-bg 40s linear;
    animation-iteration-count: infinite;
    transform-origin: center;
    transform-box: fill-box;
    z-index: -1;
  }

  &__dot-container {
    z-index: 1;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    animation: around-edges 320s linear;
    animation-iteration-count: infinite;
  }

  &__moving-dot {
    position: absolute;
    top: -100vmin;
    left: -100vmin;
    background: radial-gradient(circle,rgba(255, 113, 113, 0.7) 0,rgba(252,70,70,0) 40%);
    background-position: center;
    width: 300vmin;
    height: 300vmin;
    animation: shrinking 100s linear; 
    animation-iteration-count: infinite;
  
    &--secondary {
      top: -90vmin;
      left: -90vmin;
      animation: shrinking 120s linear; 
    }
  }
}

@keyframes slow-rotating-bg {
  0% { transform: rotate(0deg);}
  100% { transform: rotate(360deg);}
}


@keyframes around-edges {
  0% { transform: translate(90vw, -30vh); }
  14% { transform: translate(-30vw, 90vh); }
  28% { transform: translate(-65vw, -20vh); }
  42% { transform: translate(50vw, -100vh); }
  56% { transform: translate(70vw, 90vh); }
  70% { transform: translate(-120vw, -60vh); }
  84% { transform: translate(-70vw, -110vh); }
  100% { transform: translate(90vw, -30vh); }
}

@keyframes shrinking { 
  0% { transform: scale(1)}
  25% { transform: scale(0.7) }
  50% { transform: scale(1) }
  75% { transform: scale(1.6) }
  100% { transform: scale(1) }
}