.menu-placeholder {
  height: 65px;

  @media (min-width: 1024px) {
    height: 93px;
  }
}

.pre-roleplay {
  position: absolute;
  top: 0;
  bottom: 0;
  width: 100%;

  &__container {
    width: 100%;
    display: flex;
    flex: 1;
    flex-wrap: wrap;

    @include large() {
      position: absolute;
      top: 0;
      bottom: 0;
      padding: 60px 120px;
    }
  }

  &__column {
    position: absolute;
    top: 50%;
    right: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1;
    transform: translateY(-50%);

    @include large() {
      right: 40px;
    }

    &--left {
      right: unset;
      left: 5px;

      @include large() {
        left: 40px;
      }
    }

    .column-controls__button {
      display: flex;
      position: relative;
      width: 40px;
      height: 40px;
      justify-content: center;
      align-items: center;
      background: none;
      @include button-animation();

      @include large() {
        width: 60px;
        height: 60px;
      }

      &--left {
        transform: rotate(180deg);
      }

      .icon-rectangle {
        z-index: 1;
        height: 30px;

        @include large() {
          width: 45px;
          height: 45px;
        }
      }
    }

    .control-button-image {
      position: absolute;
      width: 335px;
      height: 79px;
    }
  }

  &__about {
    position: relative;
    overflow: hidden;

    @include large() {
      width: 100%;
      height: 100%;
    }

    &--played {
      z-index: 5;
      height: 100vh;
    }
  }

  &__description {
    display: flex;
    flex-direction: column;
    flex: 1;

    .close-button {
      display: flex;
      justify-content: center;
      align-items: center;
      @include button-animation();

      .icon-close {
        z-index: 1;
      }
    }

    .control-button-with-image-wrapper {
      display: flex;
      justify-content: flex-end;
      width: 100%;
      margin: 1.5em 0;
      @media (max-width: 1024px) {
        justify-content: center;
      }
    }

    .control-button-with-image {
      position: relative;
      height: 79px;
      width: 335px;
      border-radius: 25%/100%;
      font-size: 16px;
      font-weight: 500;
      background: #d2c9c2;

      &__text {
        white-space: nowrap;
        position: absolute;
        left: 32px;
        transform: translateY(-50%);
        top: 50%;
        text-transform: uppercase;
      }

      &__image {
        border-radius: 500px;
      }
    }
  }

  &__bottom-menu {
    order: 1;
    width: 100%;
    height: 80px;
    display: flex;
    justify-content: center;
    border-top: 1px solid $color-dark-blue;
  }

  &__bottom-avatar {
    display: inline-flex;
    width: 100%;
    opacity: 0.7;
    filter: grayscale(100%);
    flex-basis: 130px;

    @media (min-width: 1024px) {
      flex-basis: 160px;
    }

    &--active {
      opacity: 1;
      border-left: 1px solid $color-dark-blue;
      border-right: 1px solid $color-dark-blue;
      filter: none;

      @media (min-width: 1024px) {
        border-top: none;
      }
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center;
    }
  }
}

.about-person {
  position: relative;
  width: 200%;
  height: 200%;
  min-height: 45vh;

  @include large() {
    width: 100%;
    height: 100%;
    min-height: unset;
  }

  &__button {
    display: flex;
    align-items: center;
    padding: 7px 7px 7px 18px;
    font-size: 18px;
    color: $color-dark-blue;
    background-color: $color-champagne-pink;
    border-radius: 100px;

    &--desktop {
      display: none;

      @include large() {
        display: flex;
      }
    }

    &--mobile {
      margin: auto;
      min-width: 174px;

      @include large() {
        display: none;
      }
    }

    .icon {
      margin-left: 9px;
    }
  }

  &__video-wrapper {
    height: 100%;
  }
}

.about-btn-wrapper {
  position: absolute;
  left: 0;
  bottom: 0;
  margin-bottom: 19px;
  z-index: 1;
  width: 100vw;
  justify-content: center;
}

.description {
  &-content {
    width: auto;
    padding: 24px 20px;
    margin-bottom: auto;

    @media (min-width: 1520px) {
      margin-left: 8%;
    }

    @include large() {
      width: 100%;
      max-width: 550px;
      margin-top: auto;
      padding: 0;
    }
  }

  &-intro {
    @include large() {
      padding-bottom: 20px;
    }
  }

  &-header {
    font-size: 50px;
    line-height: 64px;
    font-weight: $font-weight-normal;
    color: $color-dark-blue;
    margin-bottom: 10px;

    @include large() {
      font-size: 60px;
      line-height: 72px;
      margin-bottom: 0;
    }
  }

  &-text {
    color: $color-dark-blue;
    font-size: 14px;
    line-height: 22px;
    margin-bottom: 12px;
    text-decoration: none;

    @include large() {
      font-size: 18px;
      line-height: 30px;
      margin-bottom: 17px;
    }

    @media (max-width: 1240px) {
      max-width: 460px;
    }

    &--bold {
      font-weight: 500;
    }
  }

  &-control-buttons-wrapper {
    display: flex;
    flex-direction: column;
    @media (max-width: 1024px) {
      flex-direction: column-reverse;
      justify-content: center;
    }
  }

  &-buttons-wrapper {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
  }

  &-button {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    min-height: 80px;
    margin-bottom: 0;
    margin-top: 0;
    padding: 28px 22px 22px;
    font-size: 22px;
    text-transform: uppercase;
    background-color: $color-champagne-pink;
    color: $color-dark-blue;

    svg {
      transform: translateY(2px);
    }

    &:first-of-type {
      @include large() {
        margin-right: 20px;
      }
    }

    @include large() {
      flex: 1;
      width: auto;
      font-size: 28px;
    }

    &--blue {
      background: $color-dark-blue;
      color: $color-champagne-pink;

      .icon-union {
        margin-right: 10px;
      }
    }
  }
}

.close-button {
  position: absolute;
  top: 40px;
  right: 0;
  cursor: pointer;
  background: transparent;
  transform: translate(-50%, -50%);
  z-index: 5;

  @media (max-width: 1024px) {
    .icon {
      width: 45px;
      height: 45px;
    }
  }

  @include large() {
    top: 80px;
  }

  &--popup {
    display: flex;
    justify-content: center;
    align-items: center;
    top: 20px;
    right: 20px;
    transform: unset;
    @include button-animation();
    background-color: rgba(30, 34, 60, 0.5);
    border-radius: 50%;

    @include large() {
      top: 40px;
      right: 60px;
    }

    .icon-close {
      z-index: 1;
    }
  }
}
