* {
  box-sizing: border-box;
}

html {
  font-size: 14px;

  @media (min-width: 768px) {
    font-size: 18px;
  }
}

.box-shadow {
  box-shadow: 0 0.25rem 0.75rem rgba(0, 0, 0, 0.05);
}

.button {
  border: none;
  font-size: inherit;
  cursor: pointer;
  padding: 0;
  background-color: transparent;

  &:hover {
    text-decoration: underline;
  }
}

.hide {
  display: none;
}

.styled-button {
  color: $color-champagne-pink;
  background-color: $color-dark-blue;
  padding: 12px 20px 12px;
  border: none;
  text-transform: uppercase;
  font-family: inherit;
  font-size: 22px;
}
