.popup {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  z-index: 5;

  .popup__video {
    height: 100%;
  }

  .video-js {
    background-color: $color-jungle-green;
    padding-top: 0 !important;

    .vjs-tech {
      object-fit: contain;
    }
  }

  .vjs-poster {
    display: none !important;
    background-image: none !important;
  }
}
