.sanofi-logo {
  position: absolute;
  top: 34px;
  right: 34px;

  img {
    height: 22px;
  }
}

.login-center {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 100%;

  @include large() {
    max-width: 600px;
  }

  &__logo {
    width: 150px;
  }

  &__text {
    line-height: 1.5;
    text-align: center;
  }

  &__link {
    font-weight: 700;
    color: $color-dark-blue;
  }

  > * {
    margin-bottom: 18px;
  }
}

.login-form {
  display: flex;
  flex-direction: column;
  min-width: 350px;

  > * {
    margin-bottom: 10px;
  }

  input {
    height: 50px;
    padding: 0px 20px;
    background-color: transparent;
    border: 1px solid $color-dark-blue;
    font-size: 18px;
    &::placeholder {
      color: $color-dark-blue;
    }
  }

  &--invalid {
    input {
      border-color: $color-error;
      color: $color-error;
      &::placeholder {color: $color-error;}
    }
  }

  &__buttons {
    display: flex;
  }

  &__subtext {
    text-align: right;
    font-size: 16px;
    color: $color-dark-blue;
  }

  &__error {
    color: $color-error;
    font-weight: 400;
    font-size: 14px;
  }
}

.login-form-button {
  @extend .styled-button;
  flex-grow: 1;

  &--back {
    color: $color-dark-blue;
    background-color: $color-champagne-pink;
    margin-right: 10px;
    order: -1;
  }
}

.dd-wrapper {
  display: flex;
  flex-direction: column;
  font-size: 18px;
  position: relative;
  min-width: 350px;

  button {
    background-color: transparent;
    border: none;
    font-size: inherit;
    cursor: pointer;
    &:hover {
      background-color: $color-champagne-pink;
    }
    transition: background-color 200ms ease-in-out;
  };
}

.dd-header {
  min-width: 350px;
  height: 50px;
  padding: 0px 20px;
  &__title {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}

.dd-arrow {
  transform: rotate(90deg);
  &--expanded {
    transform: rotate(-90deg);
  }
}

.dd-list-item {
  height: 50px;
  padding: 0px 20px;
  text-align: left;
}

.dd-list {
  display: flex;
  flex-direction: column;
}

.dd-content {
  position: absolute;
  top: 0;
  left: 0;
  border: 1px solid $color-dark-blue;
  transition: height 500ms ease-in;
}