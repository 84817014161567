.animation-overlay {
  position: absolute;
  width: 100vw;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 1;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.subtitles {
  position: absolute;
  width: 70%;
  align-items: center;
  display: flex;
  justify-content: center;

  &__word {
    opacity: 0;
    transition: 500ms opacity ease-in;

    &--show {
      opacity: 1;
    }
  }
}

.subtitles__text {
  font-size: 50px;
  line-height: 75px;
  margin: auto;
  text-align: center;

  &--small {
    font-size: 30px;
    line-height: 46px;
  }
}

@keyframes upee {
  0% {
    transform: traslateY(-200px);
  }
  100% {
    transform: traslateY(0px);
  }
}

.narrated-menu {
  position: fixed;
  bottom: 30px;
  display: flex;
  align-items: center;
  animation: 500ms fade-in;
  font-size: 14px;

  svg {
    display: flex;
  }

  &__skip {
    position: relative;
    display: flex;
    align-items: center;
    margin-left: 30px;
    padding: 5px 8px;
    text-decoration: none;
    color: $color-dark-blue;

    .icon-close, span {
      z-index: 1;
    }

    @include button-animation();
    &::before {
      top: 0;
      left: 0;
      border-radius: 30px;
    }
  }

  &__replay {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px;

    .icon-replay {
      z-index: 1;
    }

    @include button-animation();
    &::before {
      top: 0;
      left: 0;
    }
  }
}

.canvas-wrapper {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  overflow: hidden;
}

.animation-canvas {
  margin-top: 80px;
}

.intro-center {
  margin-top: auto;
  display: flex;
  flex-direction: column;
  align-items: center;

  &__logo {
    max-width: 80%;
    min-width: 220px;
    animation: 1200ms fade-in;
    height: auto;

    @include large() {
      min-width: 400px;
      padding-top: 70px;
    }

    @include medium-only() {
      min-width: 290px;
      padding-top: 70px;
    }
  }

  p {
    text-align: center;
    max-width: 640px;
    padding: 0 20px;
    line-height: 1.5em;
    margin: 14px 0 30px 0;
    opacity: 0;
    animation: 1200ms fade-in;
    animation-delay: 100ms;
    animation-fill-mode: forwards;
  }

  &__start-btn {
    @extend .styled-button;
    opacity: 0;
    animation: 1200ms fade-in;
    animation-delay: 300ms;
    animation-fill-mode: forwards;
    transition: 1s text-decoration linear;

    &:hover {
      cursor: pointer;
      text-decoration: underline;
    }
  }
}

.headphones-info {
  margin-top: auto;
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  flex-direction: column;
  opacity: 0;
  animation: 800ms fade-in;
  animation-delay: 100ms;
  animation-fill-mode: forwards;

  &__logo {
    margin-bottom: 14px;
    height: 25px;
  }

  p {
    font-size: 12px;
    font-weight: 500;
  }
}
