.sanitized-html {
  span {
    font-weight: 500;
  }

  .text {
    margin-bottom: 18px;
    font-size: 14px;
    line-height: 22px;

    &--nomargin {
      margin: 0;
    }

    @include large() {
      font-size: 18px;
      line-height: 30px;
    }
  }
}

.results-container {
  margin: 52px 0;
  overflow: auto;
  scrollbar-width: thin;
  scrollbar-color: #555 #f5f5f5;

  .text {
    margin-bottom: 8px;
  }

  table {
    width: 100%;
    margin-bottom: 30px;
    table-layout: fixed;
    min-width: 600px;

    tr {
      border-bottom: 1px solid $color-dark-blue;
      padding: 8px 0;

      &:first-child {
        border-width: 3px;
      }

      th, td {
        padding: 10px 0;
        text-align: left;
        white-space: nowrap;
      }

      th {
        font-weight: bold;
      }
    }
  }

  &::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    border-radius: 6px;
    background-color: #f5f5f5;
  }

  &::-webkit-scrollbar {
    width: 6px;
    height: 6px;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 6px;
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background-color: #555;
  }
}