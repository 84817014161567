$small-width: 480px;
$medium-width: 768px;
$large-width: 1024px;

@mixin extra-small {
  @media (max-width: #{$small-width - 1px}) {
    @content;
  }
}

@mixin small-down {
  @media (max-width: #{$medium-width - 1px}) {
    @content;
  }
}

@mixin small-only {
  @media (min-width: #{$small-width}) and (max-width: #{$medium-width - 1px}) {
    @content;
  }
}

@mixin small-up {
  @media (min-width: #{$small-width}) {
    @content;
  }
}

@mixin medium-only {
  @media (min-width: #{$medium-width}) and (max-width: #{$large-width - 1px}) {
    @content;
  }
}

@mixin medium-up {
  @media (min-width: #{$medium-width}) {
    @content;
  }
}

@mixin large {
  @media (min-width: #{$large-width}) {
    @content;
  }
}


@mixin button-animation() {
  &::before {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.5);
    border-radius: 50%;
    transform: scale(0);
    opacity: 0;
    transition-property: transform, opacity;
    transition-duration: 500ms;
  }

  &:hover {
    &::before {
      transform: scale(1);
      opacity: 1;
    }
  }
}