#onetrust-banner-sdk {
  position: fixed !important;
  bottom: 0 !important;
  outline-color: transparent !important;
}

#onetrust-banner-sdk.otCenterRounded {
  left: 0 !important;
  border-radius: 0 !important;
  top: auto !important;
  bottom: 0 !important;
  width: 100vw !important;
  max-width: 100vw !important;
  transform: none !important;
  -webkit-transform: none !important;
}

#onetrust-banner-sdk .ot-sdk-row {
  display: flex !important;
  flex-direction: column !important;
  @media (min-width: 768px) {
    flex-direction: row !important;
    align-items: center !important;
  }
}

#onetrust-banner-sdk .banner-actions-container {
  width: auto !important;
}

#onetrust-banner-sdk h2 {
  font-weight: 400 !important;
  font-size: 16px !important;
}

#onetrust-banner-sdk p {
  padding-bottom: 0.3em !important;
  font-size: 12px !important;
  line-height: 18px;
}

#onetrust-button-group {
  @media only screen and (max-width: 425px) {
    display: flex !important;
    flex-direction: column-reverse !important;
    gap: 0.5em !important;
    margin-bottom: 1em !important;
  }

  button {
    background-color: $color-dark-blue !important;
    border-radius: 0 !important;
    font-weight: 400 !important;
    border-width: 1px !important;
  }
}

#onetrust-group-container {
  padding-bottom: 1em !important;
}

#accept-recommended-btn-handler {
  background-color: $color-dark-blue !important;
  border-radius: 0 !important;
  font-weight: 400 !important;
  border-width: 1px !important;
  color: $color-champagne-pink !important;
  border-color: $color-champagne-pink !important;
}

.ot-btn-container {
  button {
    background-color: $color-dark-blue !important;
    border-radius: 0 !important;
    color: $color-champagne-pink !important;
    font-weight: 400 !important;
  }
}

.ot-link-btn,
.ot-acc-hdr * {
  color: $color-dark-blue !important;
}

.ot-switch-nob {
  background-color: $color-dark-blue !important;
}

#onetrust-consent-sdk #onetrust-banner-sdk {
  background-color: $color-dark-blue !important;
  color: $color-champagne-pink !important;
}

#onetrust-consent-sdk #onetrust-accept-btn-handler {
  background-color: $color-dark-blue !important;
  border-radius: 0 !important;
  font-weight: 400 !important;
  border-width: 1px !important;
  border-color: $color-champagne-pink !important;
  margin: 0 !important;
}

#onetrust-consent-sdk #onetrust-policy-title,
#onetrust-consent-sdk #onetrust-policy-text,
#onetrust-consent-sdk .ot-b-addl-desc,
#onetrust-consent-sdk .ot-dpd-desc,
#onetrust-consent-sdk .ot-dpd-title,
#onetrust-consent-sdk #onetrust-policy-text *:not(.onetrust-vendors-list-handler),
#onetrust-consent-sdk .ot-dpd-desc *:not(.onetrust-vendors-list-handler),
#onetrust-consent-sdk #onetrust-banner-sdk #banner-options *,
#onetrust-banner-sdk .ot-cat-header,
#onetrust-banner-sdk * {
  color: $color-champagne-pink !important;
}

#onetrust-consent-sdk #onetrust-pc-btn-handler.cookie-setting-link {
  color: $color-champagne-pink !important;
  margin-bottom: 0 !important;
}
