@keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes slide-top {
  0% {
    transform: translateY(400px);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}

@keyframes scale-up-center {
  0% {
    transform: scale(0.5);
  }
  100% {
    transform: scale(1);
  }
}

@keyframes scale-up-ver-center {
  0% {
    opacity: 0;
  }
  50% {
    opacity: .5;
  }
  100% {
    opacity: 1;
  }
}

@keyframes pulse {
  0% {
    transform: scale(1);
    opacity: 0.7;
  }
  70% {
    transform: scale(1.5);
    opacity: 0;
  }
  100% {
    transform: scale(1);
    opacity: 0;
  }
};

.slide-top {
  animation: slide-top 0.75s cubic-bezier(0.645, 0.045, 0.355, 1) both;
}

.scale-up {
  animation: scale-up-ver-center 1.5s linear;
}

.scale-up-center {
  animation: scale-up-center 0.75s cubic-bezier(0.645, 0.045, 0.355, 1) both;
}

.pulse {
  &::before {
    animation: pulse 2s linear infinite;
  }
}
