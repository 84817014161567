.static-page {
  position: fixed;
  display: flex;
  height: 100vh;
  width: 100vw;
  top: 0;
  
  &__content {
    width: 100%;
    overflow-y: auto;
    margin-top: $navbar-height-small;
    height: calc(100% - #{$navbar-height-small});
    padding: 40px 20px 40px 20px;
    word-break: break-word;
    scrollbar-width: thin;
    scrollbar-color: #555 #f5f5f5;

    &::-webkit-scrollbar-track {
      -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
      box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
      border-radius: 6px;
      background-color: #f5f5f5;
    }

    &::-webkit-scrollbar {
      width: 6px;
    }

    &::-webkit-scrollbar-thumb {
      border-radius: 6px;
      -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
      box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
      background-color: #555;
    }

    @include large() {
      margin-top: $navbar-height-big;
      height: calc(100% - #{$navbar-height-big});
      padding: 120px 0;
    }
  }
}

.static-content {
  @include medium-up() {
    text-align: unset;
    max-width: 790px;
    margin-left: auto;
    margin-right: auto;
  }

  h1 {
    font-size: 50px;
    line-height: 64px;
    
    @include large() {
      font-size: 60px;
      line-height: 72px;
    }
  }

  h3 {
    font-size: 30px;
    line-height: 44px;
    margin-top: 30px;
  }

  p {
    line-height: 22px;
    margin-top: 30px;

    @include large() {
      line-height: 30px;
    }
  }
}

.cookie-settings-btn {
  @extend .styled-button;
  margin-top: 32px;
}