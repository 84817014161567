.brightcove-react-player-loader {
  height: 100%;
}

.videoplayer {
  height: 100%;
  > {
    & div {
      height: 100%;
    }
  }
}

.video-js {
  width: 100%;
  height: 100% !important;
  padding-top: 56.25%;
  background-color: transparent;
  object-fit: cover;

  @media (min-width: 1024px) {
    padding-top: 0;
  }

  .vjs-tech {
    object-fit: cover;
  }

  .vjs-control-bar {
    width: 80%;
    background-color: transparent;
    margin: auto auto 10px;
  }

  .vjs-progress-control:hover .vjs-progress-holder {
    font-size: 18px;
  }

  .vjs-big-play-button,
  .vjs-dock-title,
  .vjs-dock-description,
  .vjs-dock-shelf,
  .vjs-picture-in-picture-control {
    display: none;
  }

  .vjs-progress-holder,
  .vjs-load-progress {
    height: 1px;
  }

  .vjs-button > .vjs-icon-placeholder:before,
  .vjs-time-control {
    color: $color-white;
  }

  .vjs-control:focus,
  .vjs-control:focus:before,
  .vjs-control:hover:before {
    text-shadow: none;
  }

  .vjs-control,
  .vjs-volume-panel.vjs-volume-panel-vertical {
    width: 2em;
  }

  .vjs-time-control {
    width: 3em;
    padding-left: 0.5em;
  }

  .vjs-play-progress,
  .vjs-volume-level {
    background-color: $color-white;
  }

  .vjs-text-track-display {
    bottom: 0 !important;
  }

  .vjs-text-track-display > div {
    margin: 0 !important;
  }

  .vjs-text-track-cue {
    width: 100% !important;
    height: auto !important;
    inset: unset !important;
    bottom: 0 !important;
    white-space: normal !important;
    display: block !important;
    font-size: 14px !important;
    line-height: 30px !important;
    color: $color-white !important;
    padding: 20px 5px !important;
    background-color: rgba(30, 34, 60, 0.5) !important;
    font-family: "AvantGarde" !important;

    @include large() {
      font-size: 18px !important;
      padding-right: 55px !important;
    }
  }

  video::-webkit-media-text-track-container {
    position: relative !important;
  }

  video::-webkit-media-text-track-display {
    height: 100% !important;
    border: 1px solid transparent;
    font-size: 14px;
    line-height: 30px;

    @include large() {
      font-size: 18px;
    }
  }

  video::-webkit-media-text-track-display-backdrop {
    @extend .vjs-text-track-cue;
    position: absolute;
    bottom: 0 !important;
    left: -6px !important;
  }
}

.default-video .video-js {
  pointer-events: none;

  .vjs-control {
    display: none;
  }
}

.default-video {
  opacity: 0;
  transition: 500ms ease-in opacity;
  &--visible {
    opacity: 1;
  }
}

.vjs-poster {
  display: none;
}

.pre-roleplay .default-video .video-js {
  .vjs-loading-spinner {
    left: 25%;

    @include large() {
      left: 50%;
    }
  }
}

.about-person .video-js {
  .vjs-tech {
    object-position: calc(-87vw);

    @media (min-width: 1024px) and (max-width: 1549px) {
      object-position: 65% top;
    }

    @media (min-width: 1550px) {
      object-position: top;
    }
  }

  .vjs-control-bar {
    width: 90vw;
    margin-left: 5vw;

    @include large() {
      width: 50vw;
      margin-left: auto;
    }
  }
}

.about-person.played-video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  .video-js {
    .vjs-tech {
      object-position: 78% top;

      @media (min-width: 1024px) and (max-width: 1549px) {
        object-position: 65% top;
      }

      @media (min-width: 1550px) {
        object-position: top;
      }
    }
    .vjs-loading-spinner {
      left: 50%;
    }
  }
}

.hear-story-vid {
  opacity: 0;
  transition: opacity 500ms;
  &--show {
    opacity: 1;
  }
}

.about-person,
.popup__video {
  .video-js .vjs-text-track-display {
    bottom: 4.5em !important;
  }

  video::-webkit-media-text-track-display-backdrop {
    bottom: 4.5rem !important;
  }
}

video::cue {
  display: block;
  width: 100%;
  font-size: 14px;
  line-height: 30px;
  color: $color-white;
  padding: 20px 5px;
  background-color: rgba(30, 34, 60, 0.5);
  font-family: "AvantGarde";

  @include large() {
    font-size: 18px;
    padding-right: 55px;
  }
}
