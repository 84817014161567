// COLORS

$color-champagne-pink: #F9DDD2;
$color-pastel-pink: #F0ADAB;
$color-super-pink: #CD5DA4;
$color-misty-rose: #EFCEC6;
$color-black-shadows: #C0B4B6;
$color-dark-blue: #1E223C;
$color-dark-blue-hovered: #3A3E55;
$color-dark-blue-disabled: #65676f;
$color-darker-blue: #15182B;
$color-white: #FFF;
$color-jungle-green: #151B17;
$color-laurel-green: #A0BD9B;
$color-error: #BC0A0A;
$color-light: #FBF2EE;

// FONT-WEIGHTS

$font-weight-normal: 400;
$font-weight-semibold: 500;
$font-weight-bold: 700;