.roleplay {
  position: fixed;
  display: flex;
  height: 100vh;
  width: 100vw;
  flex-direction: column;
  overflow-y: hidden;

  @media (min-width: 1024px) {
    flex-direction: row;
    flex-wrap: nowrap;
    position: static;
  }

  &__person,
  &__content {
    width: 100%;

    @media (min-width: 1024px) {
      flex: 0 0 50%;
    }
  }

  &__person {
    position: relative;
    flex: 0 0 auto;
    background-color: $color-champagne-pink;
    width: 100vw;
    height: 56vw; //according to video proportions

    @include large() {
      flex: unset;
      width: 100%;
      height: auto;
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  &__content {
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    flex: 1 1 auto;

    @include large() {
      flex: unset;
    }
  }

  .person-button {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 15px;
    left: 15px;
    width: 40px;
    height: 40px;
    background: none;
    transform: rotate(180deg);
    @include button-animation();

    @include large() {
      top: 30px;
      left: 30px;
      width: 60px;
      height: 60px;
    }

    .icon-rectangle {
      width: 30px;
      height: 30px;
      z-index: 1;

      @include large() {
        width: 45px;
        height: 45px;
      }
    }
  }

  .quit-modal-wrapper {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 2;
  }

  .quit-modal {
    display: flex;
    position: absolute;
    width: 95%;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    flex-direction: column;
    align-items: center;
    padding: 30px;
    background-color: $color-pastel-pink;
    z-index: 2;

    @include large() {
      max-width: 540px;
    }

    strong {
      font-weight: 500;
    }

    &__text {
      font-size: 18px;
      line-height: 30px;
      text-align: center;
      margin-top: 14px;
      margin-bottom: 16px;

      span {
        display: block;
        font-weight: 500;
      }
    }

    &__buttons-wrapper {
      display: flex;
      width: 100%;
    }

    .quit-button {
      flex: 0 0 50%;
      font-size: 22px;
      line-height: 26px;
      font-weight: 500;
      margin: 0;
      padding: 15px 0 12px;

      span {
        display: inline-block;
      }

      &--stay {
        background-color: $color-champagne-pink;
      }

      &--leave {
        color: $color-champagne-pink;
        background-color: $color-dark-blue;
      }
    }
  }

  .content-about {
    position: absolute;
    top: 5px;
    right: 5px;
    display: flex;
    min-height: 60px;
    align-items: center;
    justify-content: flex-end;

    @include large() {
      position: static;
      top: unset;
      right: unset;
      min-height: 75px;
    }

    &__card {
      display: flex;
      align-items: center;
      position: relative;
      min-width: 200px;

      font-size: 16px;

      @include large() {
        font-size: 18px;
        min-width: 230px;
      }
    }
  }

  .card-button {
    display: flex;
    align-items: center;
    margin-right: 15px;
    border-radius: 50%;
    box-shadow: 0px 0px 16px 3px $color-misty-rose;

    @include large() {
      background-color: $color-light;
      border-radius: 100px;
      box-shadow: none;
    }
    
    span {
      display: none;
      
      @include large {
        font-size: 12px;
        display: inline;
        padding-right: 10px;
        padding-left: 16px;
      }
    }
    
    @include large() {
      margin-right: 30px;
    }
    
    &--close {
      background-color: $color-dark-blue;
      transition: 500ms background-color;
      box-shadow: 0px 0px 16px 3px $color-misty-rose;
      &:hover {
        background-color: $color-dark-blue-hovered;
      }
    }
  }

  .card-history {
    padding: 20px;

    @include large() {
      padding: 0 96px 58px;
    }

    &__header {
      font-size: 30px;
      line-height: 44px;
      margin-bottom: 18px;
    }

    &__info-wrapper {
      margin-bottom: 18px;
      font-size: 14px;
      line-height: 30px;

      @include large() {
        font-size: 18px;
      }

      span {
        font-weight: 500;
      }
    }
  }

  .content-info {
    position: relative;
    background-color: $color-pastel-pink;

    strong {
      font-weight: 500;
    }

    .close-button {
      display: flex;
      top: 30px;
      right: -5px;
      z-index: 1;
      @include button-animation();

      @include large() {
        top: 40px;
        right: -1%;
      }

      .icon-close {
        z-index: 1;
      }
    }

    &__wrapper {
      text-align: center;
      padding: 35px 30px 35px;

      @include large() {
        padding: 50px 50px 34px;
      }

      .icon-mic {
        margin-bottom: 17px;
      }
    }

    &__text {
      font-size: 14px;
      line-height: 22px;
      margin-bottom: 18px;

      @include large() {
        font-size: 18px;
        line-height: 30px;
        margin-bottom: 22px;
      }

      &--bold {
        font-weight: $font-weight-semibold;
      }
    }

    &__button {
      display: flex;
      align-items: center;
      margin: auto;
      padding: 15px 24px 12px;
      font-size: 22px;
      line-height: 26px;
      font-weight: $font-weight-semibold;
      color: $color-champagne-pink;
      background-color: $color-dark-blue;
      text-transform: uppercase;

      .icon {
        margin-right: 8px;
      }
    }
  }

  .content-dialog {
    @include large() {
      overflow-y: auto;
    }

    &__button {
      position: relative;
      display: flex;
      width: 100%;
      height: 100%;
      z-index: 1;
      justify-content: center;
      align-items: center;
      background-color: $color-dark-blue;
      border-radius: 50%;
      box-shadow: 0px 0px 16px 3px $color-misty-rose;
      transition: 0.25s;

      &::after {
        content: "";
        display: block;
        position: absolute;
        top: 50%;
        left: 50%;
        width: 75%;
        height: 3px;
        transform: translate(-50%, -50%) rotate(-45deg);
        z-index: -1;
        background-color: $color-misty-rose;
      }

      &:hover {
        background-color: $color-darker-blue;
      }

      &--enabled {
        &::before {
          content: "";
          display: block;
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          border-radius: 50%;
          // transform: scale(1.1);
          z-index: -1;
          background-color: $color-dark-blue;
        }

        &::after {
          display: none;
        }
      }
    }

    &__error {
      position: fixed;
      display: flex;
      bottom: 160px;
      right: 10px;
      align-items: center;
      background-color: $color-pastel-pink;
      color: $color-dark-blue;
      font-size: 16px;
      line-height: 27px;
      font-weight: $font-weight-bold;
      padding: 13px 20px 15px;

      &::after {
        position: absolute;
        content: "";
        right: 20px;
        bottom: -9px;
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 10px 8px 0 8px;
        border-color: $color-pastel-pink transparent transparent transparent;
        transform: translateX(-45%);

        @include large() {
          right: 45%;
        }
      }

      @include large() {
        position: absolute;
        left: 50%;
        right: unset;
        transform: translate(-50%, 0);
      }

      .icon-alarm {
        margin-right: 10px;
      }
    }
  }
}

.microphone-button__wrapper {
  position: fixed;
  right: 20px;
  bottom: 25px;
  width: 64px;
  height: 64px;

  @include large() {
    position: absolute;
    left: 50%;
    right: unset;
    transform: translate(-50%, 0px);
    width: 90px;
    height: 90px;
  }
}

.waiting-video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  transition: opacity 1.5s ease;
}

.answer-video {
  @extend .waiting-video;
  opacity: 0;
  transition: opacity 1s ease;

  &--visible {
    opacity: 1;
  }
}