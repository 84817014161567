$navbar-height-small: 65px;
$navbar-height-big: 93px;

.nav {
  position: fixed;
  width: 100vw;
  display: flex;
  justify-content: space-between;
  align-items: center;
  z-index: 3;
  height: 65px;
  @include large() {
    height: 93px;
  }

  &--high {
    z-index: 5;
  }

  &__menu-btn {
    position: relative;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    padding: 13px;
    margin-left: 20px;
    transition-property: background-color;
    transition-duration: 400ms;
    @include button-animation();

    img {
      z-index: 1;
      height: 37px;
      width: 37px;
    }
  }

  &__logo {
    display: none;
    @include medium-up() {
      display: block;
      position: absolute;
      left: 50%;
      animation: 500ms fade-in;
      transform: translateX(-50%);
      * {
        max-height: 40px;
      }
    }
    &--slide {
      @include medium-up() {
        animation: 500ms logo;
      }
    }
  }

  &__sanofi {
    height: 22px;
    padding-right: 34px;
    cursor: pointer;
  }
}

@keyframes logo {
  0% {
    transform: translateY(38vh) scale(4.7) translateX(-11%);
  }
  100% {
    transform: translateY(0) scale(1) translateX(-50%);
  }
}

.menu-container {
  position: fixed;
  z-index: 3;
  display: flex;
  height: 100%;
  width: 100vw;
  background-color: $color-champagne-pink;
}

.menu {
  margin: auto;
  display: flex;
  flex-direction: column;
  text-align: center;

  &__items {
    font-size: 35px;

    @include large() {
      font-size: 60px;
      line-height: 75px;
    }

    li {
      margin-bottom: 20px;
    }

    a,
    button {
      text-transform: capitalize;
      text-decoration: none;
      color: $color-dark-blue;
      cursor: pointer;

      &:hover {
        text-decoration: underline;
      }
    }
  }

  &__info {
    position: absolute;
    left: 0;
    bottom: 9%;
    display: flex;
    width: 100%;
    justify-content: center;
    flex-wrap: wrap;

    li:not(:first-of-type) {
      margin-left: 15px;
      margin-bottom: 10px;

      @include large() {
        margin-left: 30px;
      }
    }

    a {
      text-transform: capitalize;
      text-decoration: none;
      color: $color-dark-blue;
      cursor: pointer;

      &:hover {
        text-decoration: underline;
      }
    }
  }

  &__close {
    position: absolute;
    display: flex;
    right: 20px;
    top: 20px;
    svg {
      z-index: 1;
    }
    @include button-animation();
  }

  &__user {
    margin-top: 20px;
    display: flex;
    align-items: center;
    justify-content: center;

    span {
      margin-right: 20px;
    }
  }

  &__logout {
    display: flex;
    align-items: center;
    &:hover {
      text-decoration: underline;
    }
  }

  &__copyright {
    display: flex;
    justify-content: center;
    position: absolute;
    bottom: 20px;
    font-size: 10px;
    line-height: 12px;
    width: 100%;

    &span {
      margin: auto;
    }
  }
}

//Menu slide in transition
.slide-in-enter {
  opacity: 0;
}
.slide-in-enter-active {
  opacity: 1;
  transition: all 600ms ease-in-out;
}
.slide-in-exit {
  opacity: 1;
}
.slide-in-exit-active {
  opacity: 0;
  transition: all 600ms ease-in-out;
}
